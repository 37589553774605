<template>
  <div>
    <list-layout ref="govCourse" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <donation-list-filter is-gov :filter-form="filterForm" :upload-filter="ok" />
      </template>
<!--      <template #item_sort="{row}">{{row.sort}}</template>-->
      <template
          v-if="filterForm.tab !== 'checking'"
          #item_donation_status_text="{ row }"
      >
        <list-status-text :text="row.donation_status_text" />
      </template>
      <!-- 发起机构 -->
      <template slot="item_start_institution" slot-scope="{ row }">
        <template v-if="row.start_institution.length > 1">
          <span v-for="(item, i) in row.start_institution"
          >{{ item.name }}
            {{ i === row.start_institution.length - 1 ? "" : "、" }}</span
          >
        </template>
        <!-- 只有一个机构的时候不显示丶 -->
        <template v-else-if="row.start_institution.length > 0">
          <span>{{ row.start_institution[0].name }}</span>
        </template>
        <span v-else> -- </span>
      </template>
      <!-- 支持机构 -->
      <template slot="item_sponsor_institution" slot-scope="{ row }">
        <template v-if="row.sponsor_institution.length > 1">
          <span v-for="(item, i) in row.sponsor_institution"
          >{{ item.name }}
            {{ i === row.sponsor_institution.length - 1 ? "" : "、" }}</span
          >
        </template>
        <!-- 只有一个机构的时候不显示丶 -->
        <template v-else-if="row.sponsor_institution.length > 0">
          <span>{{ row.sponsor_institution[0] }}</span>
        </template>
        <span v-else> -- </span>
      </template>
      <template slot="item_price" slot-scope="{ row }">
        <span>￥{{ row.raised }}</span
        >&nbsp;/&nbsp;<span>￥{{ row.target_price }}</span>
      </template>
      <template slot="item_user_count" slot-scope="{ row }">
        <span>{{ row.user_count }}人次</span>
      </template>
      <template slot="item_company_count" slot-scope="{ row }">
        <span>{{ row.company_count }}家</span>
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovDonations } from "@/modules/gov/api/donation-library";
import DonationListFilter from "@/modules/donation/components/project/ListFilter";
import ListStatusText from "@/base/components/List/ListStatusText";

export default {
  components: {ListStatusText, DonationListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        name: "",
        status: -1,
        donation_number: "",
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
        donation_status: -1, // 项目状态
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        { label: "ID", prop: "id", width: 100 },
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "项目名称", prop: "name", minWidth: 200 },
        { label: "项目时间", prop: "donation_time", minWidth: 220 },
        {
          label: "项目状态",
          prop: "donation_status_text",
          type: "slot",
          minWidth: 120,
        },
        {
          label: "发起机构",
          prop: "start_institution",
          minWidth: 120,
          type: "slot",
        },
        {
          label: "支持机构",
          type: "slot",

          prop: "sponsor_institution",
          minWidth: 120,
        },
        {
          label: "募捐编号",
          prop: "donation_number",
          minWidth: 120,
        },
        {
          label: "已筹金额/目标金额",
          prop: "price",
          type: "slot",
          minWidth: 200,
        },
        {
          label: "参与人次",
          prop: "user_count",
          type: "slot",
          minWidth: 200,
        },
        {
          label: "爱心企业",
          prop: "company_count",
          type: "slot",
          minWidth: 200,
        },
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovDonations(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e };
      this.$refs.govCourse.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
