<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :onExport="isGov?null:onExport" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <el-form-item label="项目名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="项目状态：" prop="donation_status">
          <el-select v-model="form.donation_status" placeholder="请选择">
            <el-option v-for="item in TypeArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="募捐编号：" prop="donation_number">
          <el-input v-model="form.donation_number" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="项目时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { donationExport } from '../../api/project'

export default {
  name: "donation-list-filter",
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    isGov: Boolean,
  },
  data() {
    return {
      rules: {},
      TypeArr: [
        { id: -1, name: "全部" },
        { id: 0, name: "未开始" },
        { id: 1, name: "进行中" },
        { id: 2, name: "已结束" },
      ],
    };
  },
  methods: {
    // 获取所属组织
    getParentList() {
      ParentList({ is_show_no_parent: 1, is_show_all_brand: 1 })
        .then((res) => {
          this.ParentArr = res.data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      donationExport(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    // this.getParentList();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    // brand_id: {
    //   get() {
    //     return this.form.brand_id == -1 ? "" : this.form.brand_id;
    //   },
    //   set(val) {
    //     this.form.brand_id = val;
    //   },
    // },
    status: {
      get() {
        return this.form.status == -1 ? "" : this.form.status;
      },
      set(val) {
        this.form.status = val;
      },
    },
    type: {
      get() {
        return this.form.type == -1 ? "" : this.form.type;
      },
      set(val) {
        this.form.type = val;
      },
    },
    sign_type: {
      get() {
        return this.form.sign_type == -1 ? "" : this.form.sign_type;
      },
      set(val) {
        this.form.sign_type = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
